import React, { useEffect, useState } from 'react';

import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';

import {
    Loading,
    Layout,
    GlobalLoading,
} from '@Components';

import { ErrorBoundary } from '@Components/ErrorBoundary/ErrorBoundary';
import {attachmentConfigStore, authStore, notificationsStore} from '@GlobalStores';
import { NotificationContainer } from '@Components/ToastNotification/ToastContainer';
import { AppSecurityModel } from './Models';

const Dashboard = React.lazy(() => import('@Pages/Dashboard/Dashboard'));
const Projects = React.lazy(() => import('@Pages/Projects/Projects'));
const Project = React.lazy(() => import('@Pages/Project/Project'));
const TypeTestMatrices = React.lazy(() => import('@Pages/TypeTestMatrix/TypeTestMatrices'));
const TypeTestMatrixRouter = React.lazy(() => import('@Pages/TypeTestMatrix/TypeTestMatrixRouter'));
const Administration = React.lazy(() => import('@Pages/Administration/Administration'));
const HealthBoard = React.lazy(() => import('@Pages/HealthBoard/HealthBoard'));
const Jobs = React.lazy(() => import('@Pages/Job/Jobs'));
const Configuration = React.lazy(() => import('@Pages/Configuration/Configuration'));
const UserAccessManagement = React.lazy(() => import('@Pages/UserAccessManagement/UserAccessManagement'));
//const Laboratory = React.lazy(() => import('@Pages/Laboratory/Laboratory'));
const RequestTimelinePage = React.lazy(() => import('@Pages/RequestTimelinePage/RequestTimelinePage'));
const Forbidden = React.lazy(() => import('@Pages/Forbidden/Forbidden'));
const NotFound = React.lazy(() => import('@Pages/NotFound/NotFound'));
const TestReportView = React.lazy(() => import('@Pages/TestReportView/TestReportView'));
const Sustainability = React.lazy(() => import('@Pages/Sustainability/Sustainability'));
const LabOfferRequest = React.lazy(() => import('@Pages/LabOfferRequest/LabOfferRequest'));
const TestPage = React.lazy(() => import('@Pages/TestPage/TestPage'));

function App() {
    const [securityModel, setSecurityModel] = useState<AppSecurityModel>(new AppSecurityModel())
    useEffect(() => {
        async function loadAppPermissions() {
            await authStore.loadAppPermissions();
            setSecurityModel(authStore.appSecurityModel);
        }
        
        attachmentConfigStore.loadData();
        loadAppPermissions();
    }, []);

    useEffect(() => {
        notificationsStore.getUserNotifications();

        return () => notificationsStore.stopUserNotifications();
    }, [])

    const { canPrincipalSeeProjects, canPrincipalSeeMatrices, canPrincipalSeeAdministration, canPrincipalSeeToolPanel, canPrincipalSeeTestReportPart, canPrincipalSeeSustainability } = securityModel;

    return (
        <ErrorBoundary>
            <NotificationContainer />
            <Router>
                <GlobalLoading />
                <Layout>
                    <React.Suspense fallback={<Loading isSuspense fullPage />}>
                        <Routes>
                            <Route path="/">
                                <Route index element={<Dashboard />} />
                                {
                                    canPrincipalSeeProjects &&
                                    <Route path='projects'>
                                        <Route index element={<Projects />} />
                                        <Route path=':projectId/*' element={<Project />} />
                                    </Route>
                                }
                                {
                                    canPrincipalSeeMatrices &&
                                    <Route path='type-test-matrices'>
                                        <Route index element={<TypeTestMatrices />} />
                                        <Route path=':matrixId/*' element={<TypeTestMatrixRouter />} />
                                    </Route>
                                }
                                {
                                    canPrincipalSeeAdministration &&
                                    <Route path='administration/*' element={<Administration />} />
                                }
                                {
                                    canPrincipalSeeToolPanel &&
                                    <>
                                        <Route path='healthboard' element={<HealthBoard />} />
                                        <Route path='job-list' element={<Jobs />} />
                                        <Route path='configuration' element={<Configuration />} />
                                        <Route path='user-access-management' element={<UserAccessManagement />} />
                                    </>
                                }
                                {/*{*/}
                                {/*    canPrincipalSeeLabsPart &&*/}
                                {/*    <Route path='laboratory/*' element={<Laboratory />} />*/}
                                {/*}*/}
                                {
                                    canPrincipalSeeTestReportPart &&
                                    <Route path='test-report-view/*' element={<TestReportView />} />
                                }
                                {
                                    canPrincipalSeeSustainability &&
                                    <Route path='sustainability/*' element={<Sustainability />} />
                                }
                                <Route path='lab-request/*' element={<LabOfferRequest />} />
                                <Route path="rt" element={<RequestTimelinePage />} />
                                <Route path='test' element={<TestPage />} />
                                <Route path='403' element={<Forbidden />} />
                                <Route path='404' element={<NotFound />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </React.Suspense>
                </Layout>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
