import {
    FormValidators,
    RequieredFields,
    ValidationErrors,
    ValidationSchema,
} from '../type-declarations';

import { isRequiered } from '../validators/form-validators';

export function getRequieredFields<T>(validation: ValidationSchema<T>, formFields: T): RequieredFields<T> {
    const requieredFields: RequieredFields<T> = {};

    for (const key in validation) {
        if (Object.prototype.hasOwnProperty.call(validation, key)) {
            const item = validation[key];
            if (item?.requiered) requieredFields[key] = true;
            if (item?.computeRequiered && item?.computeRequiered(formFields)) requieredFields[key] = true;
        }
    }
    return requieredFields;
}

export function getFormValidators<T>(validation: ValidationSchema<T>, requieredFields: RequieredFields<T>, formFields: T): FormValidators<T> {
    const validators: FormValidators<T> = {};

    for (const key in validation) {
        if (Object.prototype.hasOwnProperty.call(validation, key)) {
            const item = validation[key];

            validators[key] = item?.validators || [];
            if (item?.computeValidators) {
                validators[key] = [...validators[key]!, ...item?.computeValidators(formFields)];
            } 

            if (requieredFields[key]) {
                validators[key]?.push(isRequiered)
            }
        }
    }
    return validators;
}

export function getCrossFieldValidators<T>(validation: ValidationSchema<T>, ): FormValidators<T> {
    const validators: FormValidators<T> = {};

    for (const key in validation) {
        if (Object.prototype.hasOwnProperty.call(validation, key)) {
            const item = validation[key];
            validators[key] = item?.crossFieldValidators || [];
        }
    }
    return validators;
}

export function getInitialErrors<T>(fields: T): ValidationErrors<T> {
    const errors: Partial<ValidationErrors<T>> = {};
    for (const key in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, key)) {
            errors[key] = [];
        }
    }

    return errors as ValidationErrors<T>;
}

