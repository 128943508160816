import * as React from 'react';
import {
    ModalWindow,
    ModalDialogOptions,
    IModalDialogContent,
} from '@Components';
import { ConfirmationWithCommentDialogBody } from './ConfirmationWithCommentDialogBody';

export type ConfirmationWithCommentDialogProps = {
    title?: string;
    message?: string | string[] | JSX.Element;
    isOptional?: boolean;
};

export class ConfirmationWithCommentDialog extends React.PureComponent<ConfirmationWithCommentDialogProps> implements IModalDialogContent<void> {
    render() {
        return (
            <ConfirmationWithCommentDialogBody
                title={this.props.title}
                message={this.props.message}
                isOptional={this.props.isOptional}
            />
        );
    }

    public getModalOptions(window: ModalWindow<void>): ModalDialogOptions<void> {
        const { title } = this.props;
        return {
            title: title ?? 'Confirmation',
            width: '640px'
        };
    }
}