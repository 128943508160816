import { ObjectType } from '@AppConstants';
import {MessageTypeEnum, NotificationModel, TaskModel} from '@Models';
import { splitUid, getIdFromObjectHierarchy } from '../../Helpers/UidHelper';

const getUrl = (
    messageType: MessageTypeEnum,
    projectId: number | null,
    typeTestPlanId: number | null,
    labOfferRequestId: number | null,
    sProductFamilyVariantDocumentId: number | null,
    uamRequestId: number | null
) => {
    const projectLORUrl = `/projects/${projectId}/type-test-plan/${typeTestPlanId}/lab-requests/${labOfferRequestId}`;
    switch (messageType) {
        case MessageTypeEnum.TypeTestPlanApprovalStatusIsDraft:
        case MessageTypeEnum.TypeTestPlanApprovalStatusIsPrepared:
        case MessageTypeEnum.TypeTestPlanApprovalStatusIsInApprovalGTM:
        case MessageTypeEnum.TypeTestPlanApprovalStatusIsInApprovalPGTM: {
            return `/projects/${projectId}/type-test-plan/${typeTestPlanId}/approvals`;
        }

        case MessageTypeEnum.LabOfferRequestStatusIsOfferRequestedExternal:
        case MessageTypeEnum.LabOfferRequestStatusIsOfferAcceptedExternal:
        case MessageTypeEnum.LabOfferRequestStatusIsOfferRequestedPerTestRun:
        case MessageTypeEnum.LabOfferRequestStatusIsOfferAcceptedPerTestRun:
        case MessageTypeEnum.LabOfferRequestTestRunScheduledDateConfirmed:
        case MessageTypeEnum.LabOfferRequestTestRunPerformed:
        case MessageTypeEnum.LabOfferRequestTestRunScheduledDateUnconfirmedExternal:
        case MessageTypeEnum.LabOfferRequestTestRunPerformedInfo: {
            return projectLORUrl;
        }

        case MessageTypeEnum.SProductVariantDocumentCreated:
        case MessageTypeEnum.SProductVariantDocumentDraftSendedToInReview:
        case MessageTypeEnum.SProductVariantDocumentInReviewSendedToDraftRollback:
        case MessageTypeEnum.SProductVariantDocumentInReviewSendedToReviewed:
        case MessageTypeEnum.SProductVariantDocumentInReviewSendedToDraft:
        case MessageTypeEnum.SProductVariantDocumentReviewedSendedToInApproval:
        case MessageTypeEnum.SProductVariantDocumentInApprovalSendedToReviewed:
        case MessageTypeEnum.SProductVariantDocumentInApprovalSendedToApprovedStep1:
        case MessageTypeEnum.SProductVariantDocumentInApprovalSendedToDraft:
        case MessageTypeEnum.SProductVariantDocumentApprovedStep1SendedToApprovedStep2:
        case MessageTypeEnum.SProductVariantDocumentApprovedStep1SendedToDraft:
        case MessageTypeEnum.SProductVariantDocumentPublished:
        case MessageTypeEnum.SProductVariantDocumentUnpublished:
        case MessageTypeEnum.SProductVariantDocumentExpiring: {
            return `/sustainability/product-documents/${sProductFamilyVariantDocumentId}`;
        }
        default:
            return '';
    }
};

export const getEntityIdFromTaskModel = (model: TaskModel, objectType: ObjectType): number | null => {
    const typeWithId: [string, number] = splitUid(model.objectUniqueId);
    const isEntity = typeWithId[0] === objectType;
    let entityId: number | null = null;

    if (isEntity) {
        entityId = typeWithId[1];
    } else {
        entityId = getIdFromObjectHierarchy(model.objectHierarchy || '', objectType) || null;
    }

    return entityId;
};

export const generateLink = (model: TaskModel | NotificationModel): string => {
    const projectId = getEntityIdFromTaskModel(model, ObjectType.Project);
    const typeTestPlanId = getEntityIdFromTaskModel(model, ObjectType.TypeTestPlan);
    const labOfferRequestId = getEntityIdFromTaskModel(model, ObjectType.LabOfferRequest);
    const sProductFamilyVariantDocumentId = getEntityIdFromTaskModel(model, ObjectType.SProductFamilyVariantDocument);
    const uamRequestId = getEntityIdFromTaskModel(model, ObjectType.UamRequest);

    return getUrl(model.messageType, projectId, typeTestPlanId, labOfferRequestId, sProductFamilyVariantDocumentId, uamRequestId);
};
